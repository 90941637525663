export default {
  data: function () {
  },
  methods: {

    logList (userId = null, role = null, limit = 50, page = null, actionsOnly = null, specificAction = null, itemId = null) {
      return this.get('/api/users/logs/' + userId + '/' + role + '/' + limit + '/' + page + '/' + actionsOnly + '/' + specificAction + '/' + itemId);
    },
    logPathChanged (path) {
      return this.post('/api/users/log', {
        name: 'PATH_CHANGED',
        value: path
      });
    },
    /**
     *
     * @param type USER_CREATED, TRUCK_CREATED, BID_CREATED
     * @param id
     * @returns {*}
     */
    logDataChanged (type, updateObject) {
      return this.post('/api/users/log', {
        name: type,
        value: JSON.stringify(updateObject)
      });
    }
  }
};
