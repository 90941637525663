<template>
  <div class="text-center">
    <v-overlay :value="loading" :absolute="true">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <div v-if="error">
      <v-flex class="text-center error pa-2">
        <h1 class="title">{{error}}</h1>
      </v-flex>
    </div>
    <template v-else>
      <template v-if="$route.name !== 'invoicePrint'">
        <v-subheader v-if="parentId">
          {{$t('Invoices.parentInvoice')}}:
          &nbsp;#{{parentInvoiceNumber}}&nbsp;&mdash;&nbsp;
          <a @click="$root.isInvoicesViewAllowed ? $root.route('/invoices/page/' + $route.params.parentInvoiceId) : null">
            {{parentInvoiceName}}
          </a>
          &nbsp;${{parentInvoiceTotal.toLocaleString()}}
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click.stop="$root.addNewPageTab('/invoices/page/' + $route.params.parentInvoiceId)" v-if="$root.isInvoicesViewAllowed">
                <v-icon small class="mr-1">mdi-open-in-new</v-icon>
              </v-btn>
            </template>
            <span>{{$t('OpenExternal')}}</span>
          </v-tooltip>
        </v-subheader>

        <v-layout wrap class="pa-2">
          <v-flex shrink xs12>
            <v-layout>
              <v-flex shrink>
                <v-btn @click="$root.route('/invoices/list')" icon class="mt-4">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
              </v-flex>
              <v-flex grow xs10 class="pt-4 text-left" style="overflow: hidden;">
                <h2 class="ml-2">{{$t('Invoice')}} #{{fields.invoiceNumber}}  ${{fields.total}} <template v-if="fields.loadUID">({{fields.loadUID}})</template></h2>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex grow class="text-left mb-2" v-if="invoiceId" :style="{ 'marginTop': $route.params.id ? '16px' : '8px' }">
              <v-btn v-if="isInvoicesEditAllowed"
                     small
                     color="success"
                     class="mr-1 mt-1"
                     @click="$root.onShowInvoiceEditPreview(invoiceId, fields.invoiceNumber)">
                <v-icon left>mdi-pen</v-icon>
                {{$t('Edit')}}
              </v-btn>
              <v-btn v-if="isInvoicesEditAllowed && (fields.status === 'New' || fields.status === 'Waiting')"
                     color="text"
                     small
                     class="mr-1 mt-1"
                     @click="markAsReady">
                <v-icon left>mdi-check-decagram</v-icon>
                {{$t('Invoices.MarkAsReady')}}
              </v-btn>
              <v-btn v-if="isInvoicesEditAllowed && (fields.status === 'New' || fields.status === 'Ready' || fields.status === 'Refined' || fields.status === 'Waiting') && ($store.getters.role === 'AccountingManager' || $store.getters.role === 'SuperAdministrator')"
                     color="text"
                     small
                     class="mr-1 mt-1"
                     @click="markAsSent">
                <v-icon left>mdi-send-check</v-icon>
                {{$t('Invoices.MarkAsSent')}}
              </v-btn>
              <v-btn v-if="isInvoicesEditAllowed && (fields.status === 'Sent' || fields.status === 'Refine' || fields.status === 'Verified') && ($store.getters.role === 'AccountingManager' || $store.getters.role === 'SuperAdministrator')"
                     color="text"
                     small
                     class="mt-1"
                     @click="markAsReady">
                <v-icon left color="error">mdi-cancel</v-icon>
                {{$t('Invoices.ResetStatus')}}
              </v-btn>
              <v-btn small color="error" class="mt-1" v-if="$root.isOnline && $root.isInvoicesEditAllowed" @click.stop="parseInt(fields.deleted) === 0 ? deleteInvoice($route.params.id) : restoreInvoice($route.params.id)">
                <v-icon color="text--white" left v-html="parseInt(fields.deleted) === 0 ? 'mdi-delete' : 'mdi-delete-off'">mdi-trash-can</v-icon>
                {{ $t('Delete') }}
              </v-btn>
          </v-flex>
          <v-flex shrink>
            <v-switch
              :disabled="!isInvoicesEditAllowed"
              class="ml-2"
              inset
              v-model="resendRequired"
              :label="$root.t('Invoices.Resend')"
              @change="onResendSwitchChanged"
            />
          </v-flex>
          <v-flex class="pt-2" shrink>
            <InvoiceStatusSnippet :item="fields" :receivedDate="receivedDate"/>
          </v-flex>
          <v-flex xs12 class="text-center" v-if="invoiceId && $root.isMobile">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  style="width: calc(100% - 1rem)"
                  class="mb-1"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{$t('Invoices.Actions')}}<v-icon right>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="onInvoiceChat">
                  <v-list-item-title>
                    <v-icon left>mdi-chat</v-icon>
                    {{$t('LeftMenu.Chat')}}
                  </v-list-item-title>
                </v-list-item>
                <template v-if="isLoadsViewAllowed && additionalLinkedLoadsIds.length > 0">
                    <v-list-item @click="$root.onShowLoadPreview(fields.load_id)">
                      <v-list-item-title>{{$t('Load')}} #{{ fields.load_id }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-for="loadId in additionalLinkedLoadsIds"
                                 :key="loadId"
                                 @click="$root.onShowLoadPreview(loadId)">
                      <v-list-item-title>{{$t('Load')}} #{{ loadId }}</v-list-item-title>
                    </v-list-item>
                </template>
                <v-list-item v-if="isLoadsViewAllowed && additionalLinkedLoadsIds.length === 0" @click="$root.onShowLoadPreview(fields.load_id)">
                  <v-list-item-title>
                    <v-icon left>mdi-dolly</v-icon>
                    {{$t('GoToLoad')}}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="printInvoice()">
                  <v-list-item-title>
                    <v-icon left>mdi-printer</v-icon>
                    {{$t('Print')}}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item :href="$store.getters.host + '/api/invoices/pdf/' + $store.getters.token + '/' + this.$store.getters.currentUser.id + '/' + invoiceId" target="_blank">
                  <v-list-item-title>
                    <v-icon left>mdi-file-download</v-icon>
                    {{$t('Download')}}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="attArray.length > 0" :loading="downloadWithDocsLoading" @click="downloadWithDocs">
                  <v-list-item-title>
                    <v-icon left>mdi-folder-download</v-icon>
                    {{$t('DownloadWithAllDocuments')}}
                  </v-list-item-title>
                </v-list-item>
                <template v-if="isInvoicesEditAllowed">
                  <v-list-item @click="$root.route('/invoices/createNewVersion/' + invoiceId)">
                    <v-list-item-title>
                      <v-icon left>mdi-file-plus</v-icon>
                      {{$t('Invoices.createNewVersion')}}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <v-list-item @click="sendViaEmail" v-if="sendViaMailAllowed">
                  <v-list-item-title>
                    <v-icon left>mdi-email-send</v-icon>
                    {{$t('SendViaEmail')}}
                  </v-list-item-title>
                </v-list-item>
                <template v-if="isInvoicesEditAllowed">
                  <v-list-item @click="showLoadDocuments()">
                    <v-list-item-title>
                      <v-icon left>mdi-file-document</v-icon>
                      {{$t('Invoices.LoadDocumentsList')}}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
            <!--<v-btn class="ma-1" :disabled="prevLoadId === 0" @click="$root.route('/invoices/page/' + prevLoadId)"><v-icon>mdi-chevron-left</v-icon></v-btn>
            <v-btn class="ma-1" :disabled="nextLoadId === 0" @click="$root.route('/invoices/page/' + nextLoadId)"><v-icon>mdi-chevron-right</v-icon></v-btn>-->
          </v-flex>
          <v-flex xs12 class="text-right" v-else-if="invoiceId">
              <v-btn class="ma-1" @click="onInvoiceChat">
                <v-icon left>mdi-chat</v-icon>
                {{$t('LeftMenu.Chat')}}
              </v-btn>
              <template v-if="isLoadsViewAllowed && additionalLinkedLoadsIds.length > 0">
                <v-menu offset-y v-if="additionalLinkedLoadsIds.length > 0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" class="ma-1">
                      <v-icon left>mdi-dolly</v-icon>{{$t('GoToLoad')}}
                      <template v-if="additionalLinkedLoadsIds.length > 0">
                        ({{additionalLinkedLoadsIds.length + 1}})
                      </template>
                      <v-icon right>mdi-menu-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="$root.onShowLoadPreview(fields.load_id)">
                      <v-list-item-title>{{$t('Load')}} #{{ fields.load_id }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-for="loadId in additionalLinkedLoadsIds"
                                 :key="loadId"
                                 @click="$root.onShowLoadPreview(loadId)">
                      <v-list-item-title>{{$t('Load')}} #{{ loadId }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <v-btn class="ma-1" v-if="isLoadsViewAllowed && additionalLinkedLoadsIds.length === 0" @click="$root.onShowLoadPreview(fields.load_id)">
                <v-icon left>mdi-dolly</v-icon>
                {{$t('GoToLoad')}}
              </v-btn>
              <v-btn class="ma-1" @click="printInvoice()">
                <v-icon left>mdi-printer</v-icon>
                {{$t('Print')}}
              </v-btn>
              <v-btn class="ma-1" :href="$store.getters.host + '/api/invoices/pdf/' + $store.getters.token + '/' + this.$store.getters.currentUser.id + '/' + invoiceId" target="_blank">
                <v-icon left>mdi-file-download</v-icon>
                {{$t('Download')}}
              </v-btn>
              <v-btn class="ma-1" :disabled="attArray.length === 0" :loading="downloadWithDocsLoading" @click="downloadWithDocs">
                <v-icon left>mdi-folder-download</v-icon>
                {{$t('DownloadWithAllDocuments')}}
              </v-btn>
              <template v-if="isInvoicesEditAllowed">
                <v-btn class="ma-1" @click="$root.route('/invoices/createNewVersion/' + invoiceId)">
                  <v-icon left>mdi-file-plus</v-icon>
                  {{$t('Invoices.createNewVersion')}}
                </v-btn>
              </template>
            <!--<v-btn class="mr-1 mb-1" v-if="!isShowNotes" @click="isShowNotes = !isShowNotes">
              <v-icon left>mdi-message-bulleted</v-icon>
              {{!$vuetify.breakpoint.xs ? $t('Invoices.ShowNotes') : ''}}
            </v-btn>
            <v-btn class="mr-1 mb-1" v-else @click="isShowNotes = !isShowNotes">
              <v-icon left>mdi-message-bulleted-off</v-icon>
              {{!$vuetify.breakpoint.xs ? $t('Invoices.HideNotes') : ''}}
            </v-btn>-->
              <v-btn class="ma-1" @click="sendViaEmail" v-if="sendViaMailAllowed">
                <v-icon left>mdi-email-send</v-icon>
                {{$t('SendViaEmail')}}
              </v-btn>
              <template v-if="isInvoicesEditAllowed">
                <v-btn class="ma-1" @click="showLoadDocuments()">
                  <v-icon left>mdi-file-document</v-icon>
                  {{$t('Invoices.LoadDocumentsList')}}
                </v-btn>
              </template>
              <v-btn class="ma-1" :disabled="prevLoadId === 0" @click="$root.route('/invoices/page/' + prevLoadId)"><v-icon>mdi-chevron-left</v-icon></v-btn>
              <v-btn class="ma-1" :disabled="nextLoadId === 0" @click="$root.route('/invoices/page/' + nextLoadId)"><v-icon>mdi-chevron-right</v-icon></v-btn>
          </v-flex>
        </v-layout>

        <TroubleShootingComments :item-type="$store.getters.troubleShootingTypes.INVOICES" :item-id="invoiceId" :key="'TroubleShootingComments'+invoiceId" @fetch="fetchData"/>

        <v-layout v-if="isShowNotes">
          <v-flex xs12 class="pa-1">
            <v-textarea
              :disabled="!isInvoicesEditAllowed"
              data-cy="InvoicesNotes"
              class="mb-1 mt-2"
              :label="$t('Invoices.Notes')"
              v-model="fields.notes"
              outlined dense hide-details
            >
            </v-textarea>
            <v-btn v-if="isInvoicesEditAllowed" style="width:100%;" color="primary" @click="saveNotes">
              <v-icon left>mdi-content-save</v-icon>
              {{$t('Invoices.SaveNotes')}}
            </v-btn>
          </v-flex>
        </v-layout>

        <!--<v-layout v-if="!$root.isHideTopEditBtn" class="pa-1">
          <v-btn v-if="fields.status === 'New' || fields.status === 'Waiting'"
                 style="width:100%;"
                 class="mb-5"
                 color="text"
                 @click="markAsReady">
            <v-icon left>mdi-check-decagram</v-icon>
            {{$t('Invoices.MarkAsReady')}}
          </v-btn>
          <v-btn v-else-if="fields.status === 'Refine'"
                 style="width:100%;"
                 class="mb-5"
                 color="text"
                 @click="markAsRefined">
            <v-icon left color="warning">mdi-check-decagram</v-icon>
            {{$t('Invoices.MarkAsRefined')}}
          </v-btn>
          <v-layout wrap v-else>
            <v-flex xs12 md3>
              <v-btn style="width:90%;"
                     class="mb-5 mt-2"
                     color="text"
                     @click="markAsVerified"
                     v-if="(fields.status === 'New' || fields.status === 'Ready' || fields.status === 'Refined' || fields.status === 'Waiting') && ($store.getters.role === 'AccountingManager' || $store.getters.role === 'SuperAdministrator')">
                <v-icon left color="success">mdi-check-decagram</v-icon>
                {{$t('Invoices.MarkAsVerified')}}
              </v-btn>
            </v-flex>
            <v-flex xs12 md3>
              <v-btn style="width:90%;"
                     class="mb-5 mt-2"
                     color="text"
                     @click="markAsRefine"
                     v-if="(fields.status === 'New' || fields.status === 'Ready' || fields.status === 'Refined' || fields.status === 'Waiting') && ($store.getters.role === 'AccountingManager' || $store.getters.role === 'SuperAdministrator')">
                <v-icon left color="error">mdi-check-decagram</v-icon>
                {{$t('Invoices.MarkAsRefine')}}
              </v-btn>
            </v-flex>
            <v-flex xs12 md3>
              <v-btn style="width:90%;"
                     class="mb-5 mt-2"
                     color="text"
                     @click="markAsSent"
                     v-if="(fields.status === 'New' || fields.status === 'Ready' || fields.status === 'Refined' || fields.status === 'Waiting') && ($store.getters.role === 'AccountingManager' || $store.getters.role === 'SuperAdministrator')">
                <v-icon left>mdi-send-check</v-icon>
                {{$t('Invoices.MarkAsSent')}}
              </v-btn>
            </v-flex>
            <v-flex xs12 md3>
              <v-btn
                style="width:100%;"
                class="mb-5 mt-2"
                color="text"
                @click="markAsWFA">
                <v-icon left>mdi-check-decagram</v-icon>
                {{$t('Invoices.MarkAsWaiting')}}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-layout>
        <v-layout class="text-center">
          <v-flex xs12 class="pl-1 pr-1">
          <v-btn style="width:100%;"
                 class="mb-5 mt-2"
                 color="text"
                 @click="markAsReady"
                 v-if="(fields.status === 'Sent' || fields.status === 'Refine' || fields.status === 'Verified') && ($store.getters.role === 'AccountingManager' || $store.getters.role === 'SuperAdministrator')">
            <v-icon left color="error">mdi-cancel</v-icon>
            {{$t('Invoices.ResetStatus')}}
          </v-btn>
          </v-flex>
        </v-layout>-->

        <v-layout wrap v-if="recordPaymentsAllowed">
          <v-flex xs6 md1>
            <v-text-field
              :disabled="$root.isHideTopEditBtn"
              class="ma-1"
              data-cy="amountReceived"
              v-model="fields.amountReceived"
              :label="$t('Invoices.amountReceived')"
              prefix="$"
              type="number" step="1" @mousewheel.prevent=""
              outlined dense hide-details/>
          </v-flex>
          <v-flex xs6 md1>
            <v-text-field
              :disabled="$root.isHideTopEditBtn"
              class="ma-1"
              data-cy="amountUnderpaid"
              v-model="fields.amountUnderpaid"
              :label="$t('Invoices.amountUnderpaid')"
              prefix="$"
              type="number" step="1" @mousewheel.prevent=""
              outlined dense hide-details/>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              :disabled="$root.isHideTopEditBtn"
              class="ma-1"
              data-cy="receivedDate"
              prepend-icon="mdi-calendar"
              v-model="receivedDate"
              :label="$t('Invoices.receivedDate')"
              :rules="[rules.datetimeUSA]"
              @change="receivedDateChanged"
              outlined dense hide-details/>
          </v-flex>
          <v-flex xs12 md2>
            <v-combobox
              :disabled="$root.isHideTopEditBtn"
              class="ma-1"
              v-model="fields.paymentType"
              prepend-icon="mdi-credit-card-settings-outline"
              :items="paymentTypesItems"
              :label="$t('Invoices.paymentType')"
              outlined dense hide-details/>
          </v-flex>
          <v-flex xs12 md1>
            <v-text-field
              v-if="isFactoringPayment"
              class="ma-1"
              :disabled="$root.isHideTopEditBtn"
              data-cy="scheduleNumber"
              v-model="fields.scheduleNumber"
              :label="$t('Invoices.scheduleNumber')"
              prefix="#"
              type="number" step="1" @mousewheel.prevent="" outlined dense></v-text-field>
            <v-text-field
              v-if="!isFactoringPayment"
              class="ma-1"
              :disabled="$root.isHideTopEditBtn"
              data-cy="fees"
              v-model="fields.fees"
              :label="$t('Invoices.Fees')"
              prefix="%"
              type="number" step="1" @mousewheel.prevent=""
              outlined dense hide-details/>
          </v-flex>
          <template v-if="isPaymentRecorded">
              <v-flex xs12 md2>
                <v-btn :disabled="$root.isHideTopEditBtn" class="ma-1 text-center" style="width:90%" color="primary" @click="recordPayment">{{$t('Invoices.UpdatePayment')}}</v-btn>
              </v-flex>
              <v-flex xs12 md2>
                <v-btn :disabled="$root.isHideTopEditBtn" class="ma-1 text-center" style="width:90%" color="error"   @click="resetRecordPayment">{{$t('Invoices.ResetRecordPayment')}}</v-btn>
              </v-flex>
          </template>
          <template v-else>
            <v-flex xs12 md4>
              <v-btn :disabled="$root.isHideTopEditBtn" class="ma-1 text-center" style="width: calc(100% - 1rem)" color="primary" @click="recordPayment"><v-icon left>mdi-cash-usd</v-icon>{{$t('Invoices.RecordPayment')}}</v-btn>
            </v-flex>
          </template>
        </v-layout>
      </template>
      <div style="overflow: auto;">
        <InvoiceTemplate v-if="$route.name === 'invoicePrint'"
                         :fields="fields"
                         :key="'InvoiceTemplate'+lastDataUpdate"
                         :print="$route.name === 'invoicePrint'"
                         :logoPath="logoPath"
                         :invoiceContactInfo="invoiceContactInfo"
                         :invoiceFactoringShipTo="invoiceFactoringShipTo"
        />
      </div>

      <v-layout wrap v-if="$route.name !== 'invoicePrint'">
        <v-flex xs12 md5 xl6 class="pa-2" v-if="$route.name !== 'invoicePrint'">
          <v-btn class="primary mb-1" width="100%" @click="saveDocuments();" v-if="fields.load_id <= 0 && fields.invoiceDocumentsUPLOAD !== '[]'">
            <v-icon left>fa-save</v-icon>{{$t('Invoices.SaveDocuments')}}
          </v-btn>
          <v-card class="pl-1 pr-1">
            <v-card-text>
              <h4 v-if="!fields.invoiceDocumentsUPLOAD || fields.invoiceDocumentsUPLOAD === '[]'">Documents is not selected.</h4>
              <MultiFileUpload v-if="!isFieldHidden('invoiceDocumentsUPLOAD')"
                               :downloadAllowed="isFieldDownloadAllowed('invoiceDocumentsUPLOAD')"
                               :readonly="fields.load_id > 0"
                               accept="application/pdf"
                               height="800px"
                               :deleteAllowed="!isFieldHidden('deleteDocuments') && !isFieldReadOnly('deleteDocuments')"
                               :field="fields.invoiceDocumentsUPLOAD"
                               :label="$t('Invoices.invoiceDocumentsUPLOAD')"
                               @change="fileChange('invoiceDocumentsUPLOAD', $event)"
                               fieldName="invoiceDocumentsUPLOAD"
                               uploadFolder="invoiceDocumentsUPLOAD"
                               :showSizeWarning="true"
                               :key="fields.invoiceDocumentsUPLOAD"
                               />
            </v-card-text>
            <v-card-actions>
              <v-btn class="primary mt-1" width="100%" @click="saveDocuments();" v-if="fields.load_id <= 0">
                <v-icon left>fa-save</v-icon>{{$t('Invoices.SaveDocuments')}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
        <v-flex xs12 md7 xl6 class="mt-2" style="overflow: auto;">
          <InvoiceTemplate :fields="fields"
                           :key="'InvoiceTemplate'+lastDataUpdate"
                           :logoPath="logoPath"
                           :invoiceContactInfo="invoiceContactInfo"
                           :invoiceFactoringShipTo="invoiceFactoringShipTo"
          />
        </v-flex>
      </v-layout>
    </template>
    <v-layout class="text-left pt-5" v-if="$route.name !== 'invoicePrint'">
      <MailsThread v-if="invoiceId" :invoiceId="invoiceId" :isNotModal="true"/>
    </v-layout>

    <v-dialog v-model="sendViaEmailModal" scrollable fullscreen persistent hide-overlay transition="dialog-bottom-transition" v-if="sendViaMailAllowed">
      <EmailSendForm :attFilesArray="attArray"
                     :recipientsList="defaultInvoiceRecipientsList.concat(brokerRecipientsList)"
                     :title="sendTitle"
                     :invoice="invoiceId"
                     @closed="closeSendViaEmailModal"
                     @sent="markAsSent"
      ></EmailSendForm>
    </v-dialog>

    <v-dialog v-model="documentsModal" scrollable v-if="isInvoicesEditAllowed">
      <v-card>
        <v-card-title>
          <h2>{{$t('Loads.uploadDocuments')}}</h2><v-spacer></v-spacer><v-btn icon @click="documentsModal=false;"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <LoadDocuments :key="documentsModalKey"
                       :fields="loadFields"
                       :deleteAllowed="!isFieldHidden('deleteDocuments') && !isFieldReadOnly('deleteDocuments')"
                       :loadId="fields.load_id">

        </LoadDocuments>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import axios from 'axios';
import InvoiceTemplate from '../../components/InvoiceTemplate.vue';
import userFormsCommonMixin from '../../mixins/userFormsCommonMixin';
import invoicesMixin from '../../mixins/invoicesMixin';
import fieldLoadingMixin from '../../mixins/fieldLoadingMixin';
import fieldAccessRightMixin from '../../mixins/fieldAccessRightsMixin';
import MultiFileUpload from '../../components/MultiFileUpload.vue';
import EmailSendForm from '../../components/EmailSendForm.vue';
import LoadDocuments from '../../components/LoadDocuments.vue';
import MailsThread from '../../components/MailsThread';
import InvoiceStatusSnippet from '../../components/InvoiceStatusSnipet';
import TroubleShootingComments from '../../components/TroubleShootingComments';

export default {
  components: { TroubleShootingComments, InvoiceStatusSnippet, InvoiceTemplate, MultiFileUpload, EmailSendForm, LoadDocuments, MailsThread },
  mixins: [userFormsCommonMixin, fieldLoadingMixin, fieldAccessRightMixin, invoicesMixin],
  props: ['id'],
  data () {
    return {
      publicPath: process.env.BASE_URL,
      fields: {
        status: '',
        parent: 0,
        load_id: 0,
        broker: 0,
        accountManagers: '[]',
        createdPST: '',
        date: '',
        dueDate: '',
        invoiceNumber: 0,
        scheduleNumber: 0,
        billTo: '',
        shipTo: '',
        terms: '',
        shipDate: '',
        loadNumber: '',
        entries: '[]',
        loadUID: '',
        /* Shipper: '',
        Consignee: '',
        DescriptionDimensions: '',
        Amount: 0, */
        total: 0,
        amountReceived: 0,
        receivedDate: 0,
        paymentType: '',
        notes: '',
        invoiceDocumentsUPLOAD: '[]',
        dueLeftSeconds: 0,
        amountUnderpaid: 0,
        sentDatePST: '',
        receivedDatePST: '',
        fees: 0,
        resendRequired: 0,
        factoring: 0,
        headers: {
          DateHeader: 'Date',
          InvoiceNumberHeader: 'Invoice #',
          BillToHeader: 'Bill To',
          ShipToHeader: 'Ship To',
          TermsHeader: 'Terms',
          ShipDateHeader: 'Ship Date',
          LoadNumberHeader: 'Load Number',
          ShipperHeader: 'Shipper',
          ConsigneeHeader: 'Consignee',
          DescriptionHeader: 'Description/Dimensions',
          AmountHeader: 'Amount',
          TotalHeader: 'Total',
          ThankYouHeader: 'Thank you for your business.'
        }
      },
      sendViaEmailModal: false,
      documentsModal: false,
      documentsModalKey: new Date().getTime(),
      loadFields: {},
      attArray: [],
      sendTitle: '',
      brokerRecipientsList: [],
      defaultInvoiceRecipientsList: [],
      formatedDate: '',
      formatedDueDate: '',
      formatedShipDate: '',
      entries: [],
      loading: false,
      error: false,
      receivedDate: '',
      rules: {
        datetimeUSA: value => new Date(value).getTime() ? true : false || this.$root.t('Loads.DateFormatError')
      },
      prevLoadId: 0,
      nextLoadId: 0,
      isPaymentRecorded: false,
      isShowNotes: true,
      parentInvoiceNumber: 0,
      parentInvoiceName: '',
      parentInvoiceTotal: 0,
      parentId: 0,
      parentAmountReceived: 0,
      downloadWithDocsLoading: false,
      recordPaymentsAllowed: false,
      sendViaMailAllowed: false,
      resendRequired: false,
      lastDataUpdate: 0,
      additionalLinkedLoadsIds: [],
      paymentTypesItems: [],/* 'Cash','Check','ACH','Wire Transfer','QuickPay','Factoring' */
      invoiceContactInfo: '',
      invoiceFactoringShipTo: '',
      logoPath: ''
    };
  },
  computed: {
    invoiceId () {
      return this.id || this.$route.params.id;
    },
    isFactoringPayment () {
      return this.fields.paymentType === 'Factoring';
    }
  },
  watch: {
  },
  mounted () {
    this.$root.getInvoiceLogoPath().then(path => {
      this.logoPath = path;
    });
    /*this.$root.getGlobalSettingsField('invoiceContactInfo').then((response) => {
      if (response.status === 'ok') {
        this.invoiceContactInfo = response.result;
      }
    });
    this.$root.getGlobalSettingsField('invoiceFactoringShipTo').then((response) => {
      if (response.status === 'ok') {
        this.invoiceFactoringShipTo = response.result;
      }
    });
    if (this.invoiceId) {
      this.$root.getGlobalSettingsField('invoiceDefaultRecipientsList').then((response) => {
        if (response.status === 'ok') {
          if (response.result && response.result.indexOf('[') === 0) {
            this.defaultInvoiceRecipientsList = JSON.parse(response.result);
          }
        }
      });

      this.$root.getGlobalSettingsField('invoicePaymentTypes').then((response) => {
        if (response.status === 'ok') {
          this.paymentTypesItems = JSON.parse(response.result);
        }
      });*/

    if (this.invoiceId) {
      this.fetchData();
    }
  },
  beforeDestroy () {
    this.$root.contextSave = () => {};
  },
  methods: {
    onResendSwitchChanged () {
      if (this.invoiceId) {
        this.$root.saveInvoice({
          resendRequired: this.resendRequired ? 1 : 0
        }, this.invoiceId).then((response) => {
          if (response.status === 'ok') {
            this.$root.toast(this.$root.t('ChangesAreSaved'), {color: 'success'});
          }
        });
      }
    },
    onInvoiceChat () {
      const invoiceId = this.invoiceId;
      this.$root.createNewThreadForItem(3, invoiceId).then((response) => {
        if (response.status === 'ok') {
          this.$root.route('/chats/list/' + response.threadId + '/' + response.userThreadId);
        }
      });
    },
    downloadWithDocs () {
      this.downloadWithDocsLoading = true;
      axios({
        url: this.$store.getters.host + '/api/invoices/downloadWithDocuments/' + this.$store.getters.token + '/' + this.$store.getters.currentUser.id + '/' + this.invoiceId,
        method: 'GET',
        responseType: 'blob' // important
      }).then((response) => {
        let today = new Date();
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'invoice#' + this.fields.invoiceNumber + '_' + today.toLocaleString() + '_with_documents.pdf'); // or any other extension
        document.body.appendChild(link);
        link.click();
        this.downloadWithDocsLoading = false;
      });
    },
    showLoadDocuments () {
      const loadId = parseInt(this.fields.load_id);
      this.$root.getLoadDocuments(loadId).then((response) => {
        if (response.status === 'ok') {
          this.loadFields = response.results;
          this.documentsModalKey = new Date().getTime();
          this.documentsModal = true;
        }
      });
    },
    printInvoice () {
      this.$root.route('/invoices/print/' + this.invoiceId);
      setTimeout(() => {
        window.print();
      }, 1000);
    },
    sendViaEmail () {
      this.sendViaEmailModal = true;
    },
    closeSendViaEmailModal () {
      this.sendViaEmailModal = false;
    },
    receivedDateChanged (value) {
      this.fields.receivedDate = this.$root.getTimestampFromDateTimeString(value);
    },
    resetRecordPayment () {
      this.$dialog.confirm(this.$root.t('Invoices.ResetRecordPaymentConfirmationMsg')).then(dialog => {
        // Save
        if (this.invoiceId) {
          this.$root.saveInvoice({
            status: 'Sent',
            amountReceived: 0,
            scheduleNumber: 0,
            receivedDate: 0,
            paymentType: '',
            factoringFee: 0,
            factoringScheduleFee: 0,
            amountUnderpaid: 0
          }, this.invoiceId).then((response) => {
            if (response.status === 'ok') {
              this.isPaymentRecorded = false;
              this.fetchData();
              this.$root.route('/invoices/page/' + this.invoiceId);
            }
          });
        }
      }).catch(() => {
      });
    },
    recordPayment () {
      this.$dialog.confirm(this.$root.t('Invoices.RecordPaymentConfirmationMsg')).then(dialog => {
        let status = '';
        let totalAmount = this.fields.total;
        if (parseFloat(this.fields.fees) > 0) {
          totalAmount = this.fields.total - (this.fields.total * (parseFloat(this.fields.fees) / 100.0));
        }
        //console.log(this.fields.total, totalAmount);
        if (parseInt(this.fields.amountReceived) === 0 && parseInt(this.fields.receivedDate) > 0) {
          status = 'Not Paid';
        } else if (parseInt(this.fields.amountReceived) < parseInt(totalAmount) && parseInt(this.fields.amountReceived) < parseInt(totalAmount) - parseInt(this.parentAmountReceived)) {
          if (!this.fields.amountUnderpaid || this.fields.amountUnderpaid <= 0) {
            this.fields.amountUnderpaid = totalAmount - this.fields.amountReceived;
          }
          status = 'Underpaid';
        } else {
          status = 'Paid';
        }
        // Save
        if (this.invoiceId) {
          this.$root.saveInvoice({
            status: status,
            paidFuse: 1,
            fees: this.fields.fees,
            scheduleNumber: this.fields.scheduleNumber,
            amountReceived: this.fields.amountReceived,
            receivedDate: this.$root.getTimestampFromDateTimeString(this.receivedDate),
            paymentType: this.fields.paymentType,
            amountUnderpaid: this.fields.amountUnderpaid
          }, this.invoiceId, true).then((response) => {
            if (response.status === 'ok') {
              this.isPaymentRecorded = false;
              this.fetchData();
              this.$root.route('/invoices/page/' + this.invoiceId);
            }
          });
        }
      }).catch(() => {
      });
    },
    saveNotes () {
      return new Promise((resolve, reject) => {
        this.$dialog.confirm(this.$root.t('Invoices.SaveNotesConfirmationMsg')).then(dialog => {
          // Save
          if (this.invoiceId) {
            this.$root.saveInvoice({ notes: this.fields.notes }, this.invoiceId);

            if (parseInt(this.fields.load_id) > 0) {
              this.$root.saveLoad({ notes: this.fields.notes }, this.fields.load_id);
            }
          }
        }).catch(() => {
        });
      });
    },
    saveDocuments () {
      return new Promise((resolve, reject) => {
        this.$dialog.confirm(this.$root.t('Invoices.SaveConfirmationMsg')).then(dialog => {
          // Set specific fields

          // Save
          if (this.invoiceId) {
            this.$root.saveInvoice({ invoiceDocumentsUPLOAD: this.fields.invoiceDocumentsUPLOAD }, this.invoiceId).then((response) => {
              if (response.status === 'ok') {
                // this.$root.route('/invoices/page/' + this.invoiceId);
              }
            });
          }
        }).catch(() => {
        });
      });
    },
    markAsSent () {
      if (this.invoiceId) {
        this.$root.markInvoiceAsSent(this.invoiceId).then(() => {
          this.$root.refresh();
        });
        this.fields.status = 'Sent';
      }
    },
    markAsReady () {
      if (this.invoiceId) {
        this.$root.markInvoiceAsReady(this.invoiceId).then(() => {
          this.$root.refresh();
        });
        this.fields.status = 'Ready';
      }
    },
    markAsVerified () {
      if (this.invoiceId) {
        this.$root.markInvoiceAsVerified(this.invoiceId).then(() => {
          this.$root.refresh();
        });
        this.fields.status = 'Verified';
      }
    },
    markAsRefine () {
      if (this.invoiceId) {
        this.$root.markInvoiceAsRefine(this.invoiceId).then(() => {
          this.$root.refresh();
        });
        this.fields.status = 'Refine';
      }
    },
    markAsRefined () {
      if (this.invoiceId) {
        this.$root.markInvoiceAsRefined(this.invoiceId).then(() => {
          this.$root.refresh();
        });
        this.fields.status = 'Refined';
      }
    },
    markAsWFA () {
      if (this.invoiceId) {
        this.$root.markInvoiceAsWaiting(this.invoiceId).then(() => {
          this.$root.refresh();
        });
        this.fields.status = 'Waiting';
      }
    },

    save () {
      return new Promise((resolve, reject) => {
        this.$dialog.confirm(this.$root.t('Invoices.SaveConfirmationMsg')).then(dialog => {
          // Set specific fields

          // Save
          if (this.invoiceId) {
            this.$root.saveInvoice(this.fields, this.invoiceId).then((response) => {
              if (response.status === 'ok') {
                this.$root.route('/invoices/page/' + this.invoiceId);
              }
            });
          }
        }).catch(() => {
        });
      });
    },
    deleteInvoice (id) {
      this.$dialog.confirm(this.$root.t('Invoices.DeleteConfirmationMsg')).then(dialog => {
        this.$root.deleteInvoice(id);
        this.$root.refresh();
      });
    },
    restoreInvoice (id) {
      this.$dialog.confirm(this.$root.t('Invoices.RestoreConfirmationMsg')).then(dialog => {
        this.$root.restoreInvoice(id);
        this.$root.refresh();
      });
    },
    fetchData () {
      let i = 0;
      if (!this.invoiceId && !this.isInvoicesCreateAllowed) {
        this.error = this.$root.t('Errors.AccessDeniedAuthorizationFailed');
        this.loading = false;
        return;
      }

      if (this.invoiceId) {
        if (!this.isInvoicesViewAllowed) {
          this.error = this.$root.t('Errors.AccessDeniedAuthorizationFailed');
          this.loading = false;
          return;
        }
      }

      this.updatingEnabled = true;
      this.applyRights('', 'invoices', {
        deleteDocuments: 0
      });

      this.loading = true;
      this.$root.getInvoice(this.invoiceId).then(async (response) => {
        if (response.status === 'ok') {

          this.invoiceContactInfo = response.invoiceContactInfo;
          this.invoiceFactoringShipTo = response.invoiceFactoringShipTo;
          if (response.invoiceDefaultRecipientsList && response.invoiceDefaultRecipientsList.indexOf('[') === 0) {
            this.defaultInvoiceRecipientsList = JSON.parse(response.invoiceDefaultRecipientsList);
          }
          if (response.invoicePaymentTypes && response.invoicePaymentTypes.indexOf('[') === 0) {
            this.paymentTypesItems = JSON.parse(response.invoicePaymentTypes);
          }


          this.$root.isHideTopEditBtn = !response.isEditAllowed;
          this.additionalLinkedLoadsIds = [];
          for (i = 0; i < response.linkedLoadsIds.length; i++) {
            this.additionalLinkedLoadsIds.push(response.linkedLoadsIds[i].loadId);
          }
          this.recordPaymentsAllowed = response.recordPaymentsAllowed;
          this.sendViaMailAllowed = response.sendViaMailAllowed;
          // Set prev next id
          if (response.result['prevId']) {
            this.prevLoadId = parseInt(response.result['prevId']);
          }
          if (response.result['nextId']) {
            this.nextLoadId = parseInt(response.result['nextId']);
          }

          this.fields.sentDatePST = response.result['sentDatePST'];
          this.fields.receivedDatePST = response.result['receivedDatePST'];

          this.fields.status = response.result['status'];
          this.fields.parent = response.result['parent'];
          this.parentId = parseInt(this.fields.parent);
          if (this.parentId > 0) {
            this.$root.getInvoice(this.parentId).then((response2) => {
              if (response2.status === 'ok') {
                this.parentInvoiceNumber = response2.result['invoiceNumber'];
                this.parentInvoiceName = response2.result['name'];
                this.parentInvoiceTotal = response2.result['total'];
              }
            });
          }
          this.fields.load_id = response.result['load_id'];
          this.fields.broker = response.result['broker'];
          this.brokerRecipientsList = await this.$root.getRecipientsListByBroker(this.fields.broker);
          // accountManagers: '[]',
          this.fields.createdPST = response.result['datePST'];
          this.fields.date = response.result['date'] ? response.result['date'].split('T')[0] : null;
          this.fields.dueDate = response.result['dueDate'] ? response.result['dueDate'].split('T')[0] : null;
          this.fields.invoiceNumber = response.result['invoiceNumber'];
          this.fields.scheduleNumber = response.result['scheduleNumber'];
          this.fields.loadUID = response.result['loadUID'];
          this.fields.billTo = response.result['billTo'];
          this.fields.shipTo = response.result['shipTo'];
          this.fields.terms = response.result['terms'];
          this.fields.shipDate = response.result['shipDate'] ? response.result['shipDate'].split('T')[0] : null;
          this.fields.loadNumber = response.result['loadNumber'];
          this.fields.total = response.result['total'];
          this.fields.entries = response.result['entries'];
          this.fields.notes = response.result['notes'];
          this.fields.dueLeftSeconds = response.result.dueLeftSeconds;
          this.fields.amountUnderpaid = response.result.amountUnderpaid;
          this.fields.fees = response.result.fees;
          this.fields.resendRequired = response.result.resendRequired;
          this.fields.factoring = response.result.factoring;
          this.fields.deleted = response.result.deleted;
          this.resendRequired = parseInt(response.result.resendRequired) === 1;

          // Set headers if they available
          for (const [header, value] of Object.entries(this.fields.headers)) {
            if (response.result.hasOwnProperty(header)) {
              this.fields.headers[header] = response.result[header];
            }
          }

          // Add invoice pdf
          /* const invoiceUrl = this.$store.getters.host + '/api/invoices/pdf/' + this.$store.getters.token + '/' + this.$store.getters.currentUser.id + '/' + this.invoiceId;
          this.attArray.push({
              attUrl: invoiceUrl,
              downloadAttUrl: invoiceUrl,
              isPdf: true,
              folder: true,
              file: true,
              directDownload: true,
              filename: 'invoice '+this.fields.invoiceNumber+'.pdf',
          }); */

          this.attArray.push(this.$root.genInvoiceFileAttObject(this.invoiceId, this.fields.invoiceNumber));

          if (response.result['invoiceDocumentsUPLOAD']) {
            this.fields.invoiceDocumentsUPLOAD = response.result['invoiceDocumentsUPLOAD'];
            this.attArray = this.attArray.concat(this.$root.genInvoiceDocumentsAttObject(this.fields.invoiceDocumentsUPLOAD));

            /*
              const filesArray = JSON.parse(this.fields.invoiceDocumentsUPLOAD);
              for (let i = 0; i < filesArray.length; i++) {
                  const fileData = filesArray[i];
                  let uploadFolder = fileData.uploadFolder ? fileData.uploadFolder : 'invoiceDocumentsUPLOAD';

                  this.attArray.push({
                      attUrl: this.$root.getImageUrl(uploadFolder, fileData.hash),
                      downloadAttUrl: this.$root.getImageUrl(uploadFolder, fileData.hash, fileData.name),
                      isPdf: !!fileData.name.split('.').pop().match(/pdf/gi),
                      folder: uploadFolder,
                      file: fileData.hash,
                      filename: fileData.name,
                      isImageLoaded: false
                  });
              } */
          }

          this.sendTitle = this.$root.genInvoiceEmailSubject(this.fields.invoiceNumber, this.fields.total);

          this.fields.paymentType = response.result['paymentType'];
          // Load notes from load if load_id available
          /* if (parseInt(this.fields.load_id) > 0) {
            this.fields.notes = response.result['loadNotes'];
          } */

          this.parentAmountReceived = response.result['parentAmountReceived'] ? parseInt(response.result['parentAmountReceived']) : 0;

          this.fields.receivedDate = response.result['receivedDate'];

          if (parseInt(response.result['receivedDate']) > 0) {
            this.isPaymentRecorded = true;
            this.receivedDate = this.$root.getDateTimeStringFromTimestamp(this.fields.receivedDate);
          } else {
            // Set current datetime
            this.receivedDate = this.$root.getDateTimeStringFromTimestamp(parseInt(this.$moment.moment().format('X')));
          }

          if (response.result['amountReceived']) {
            this.fields.amountReceived = response.result['amountReceived'];
          } else if (!this.isPaymentRecorded) {
            if (parseInt(response.result['parentAmountReceived'])) {
              this.fields.amountReceived = this.fields.total - parseInt(response.result['parentAmountReceived']);
            } else {
              this.fields.amountReceived = this.fields.total;
            }
          } else {
            this.fields.amountReceived = 0;
          }

          if (this.fields.date) {
            let _dateArray = this.fields.date.split('T')[0].split('-');
            this.formatedDate = _dateArray[1] + '/' + _dateArray[2] + '/' + _dateArray[0];
          }

          if (this.fields.dueDate) {
            let _dateArray = this.fields.dueDate.split('T')[0].split('-');
            this.formatedDueDate = _dateArray[1] + '/' + _dateArray[2] + '/' + _dateArray[0];
          }

          if (this.fields.shipDate) {
            let _dateArray = this.fields.shipDate.split('T')[0].split('-');
            this.formatedShipDate = _dateArray[1] + '/' + _dateArray[2] + '/' + _dateArray[0];
          }
        } else {
          this.error = response.msg;
        }

        this.lastDataUpdate = new Date().getTime();
        this.loading = false;
      });
    }
  }
};
</script>
